import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../../utils/style';
import StarsFull from './StarsRealFull';
import StarsEmpty from './StarsRealEmpty';
import { withdrawCommentsListUrl } from './Config.js';
import UserIcon from './StarIcons/UserIcon';
import Comment from '../../../../icons/svg/Comment';

const Wrap = styled('div')`
  text-align: left;
  .reviews-list {
    list-style: none;
    padding-left: 0;
  }
  .li {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin: 5px 0;
    padding: 10px 40px 10px 0;
    &:nth-of-type(4n+0) {
      padding-right:0;
    }
  }
  .h3 {
    margin: 20px 0 40px;
    text-align: center;
  }
  .star-holder, .user-details {
    display: inline-block;
    vertical-align: top;
  }
  .user-details {
    padding: 0 0 0 15px;
    width: calc(100% - 60px);
  }
  .star-holder {
    .emoji-wrap {
      display: inline-block;
      background: ${color.transparentWhite1};
      width: 40px;
      height: 40px;
      padding: 4px;
      border-radius: 20px;
    }
    svg {
      background: ${color.transparentWhite1};
      border-radius: 20px;
    }
  }
  .user-name {
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1;
    white-space: nowrap;
    display: block;
    margin-bottom: 5px;
  }
  .request-time {
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    display: inline-block;
  }
  .button {
    margin: 30px 0 20px;
  }
  
  @media (max-width: ${breakpoints.lg}) {
    .li {
      width: 50%;
      &:nth-of-type(3n+0) {
        padding-right:40px;
      }
      &:nth-of-type(2n+0) {
        padding-right:0;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .h3 {
      margin: 0;
      max-width: 60%;
      min-height: 50px;
      text-align: left;
      svg {
        flex: 0 0 49px;
      }
    }
    .li {
      width: 100%;
      padding-right:0;
      &:nth-of-type(3n+0) {
        padding-right:0;
      }
      &:nth-of-type(2n+0) {
        padding-right:0;
      }
    }
    .request-time {
      float: right;
    }
  }
`;

const RatingHolder = styled('div')`
  float:left;
  margin: 2px 10px 3px 0;
  position: relative;
  font-size: 20px;
  height: 18px;
  width: 80px;
  .rating {
    width: 140px;
    height: 28px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    span {
      width:16px;
      height: 16px;
      font-size:12px;
      text-align: center;
      display: block;
      float:left;
    }
  }
  .rating-passive {
    z-index: 0;
  }
  .rating-active {
    z-index: 1;
  }
`;

const ReviewText = styled('div')`
  color: ${color.white};
  margin-top: 10px;
  overflow: hidden;
  opacity: 0.8;
  font-size: 14px;
  line-height: 22px;
  max-height: 110px;
  overflow: hidden;
`;

const loadCommentsData = async (page, pageSize, firstcomments, comments, setComments, setTotalPages) => {
  if (page === 1) {
    return false;
  }
  const lastWordpressId = (firstcomments.length > 0) ? firstcomments[firstcomments.length - 1].node.wordpress_id : false;
  const listingUrl = withdrawCommentsListUrl + `/?per_page=${pageSize}&page=${page}&before_id=${lastWordpressId}`;
  const response = await fetch(listingUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    const totalPages = await response.headers.get('X-WP-TotalPages');
    setTotalPages(totalPages);
    const json = await response.json();
    const data = loadMorePrepare(json);
    comments.concat(data);
    setComments(comments.concat(data));
  }
};

const firstcommentsPrepare = (firstcomments) => {
  const firstcommentsPrepared = firstcomments.edges.map(firstcomment => {
      return {
        id: firstcomment.node.id,
        wordpress_id: firstcomment.node.wordpress_id,
        rating: firstcomment.node.rating,
        emoji: firstcomment.node.emoji,
        author_name: firstcomment.node.name,
        content: firstcomment.node.comment,
        request_time: firstcomment.node.request_time
      };
    }
  );
  return firstcommentsPrepared;
};

const loadMorePrepare = (data) => {
  let comments = data.map(comment => {
    return {
      id: comment.id,
      wordpress_id: comment.id,
      rating: comment.rating,
      emoji: comment.emoji,
      author_name: comment.name,
      content: comment.comment,
      request_time: comment.request_time
    };
  });
  return comments;
};

const CommentsStaticLoadmore = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allWordpressFreespinWithdrawcommentsfirst {
          edges {
            node {
            id
            emoji
            name
            rating
            request_time
            wordpress_id
            comment
          }
        }
      }              
    }
    `
    }
    render={data => {
      const pageSize = 4;
      const [page, setPage] = useState(1);
      const [totalPages, setTotalPages] = useState(false);
      const firstcomments = data.allWordpressFreespinWithdrawcommentsfirst;
      const [comments, setComments] = useState(firstcommentsPrepare(firstcomments));
      useEffect(() => {
        loadCommentsData(page, pageSize, firstcomments, comments, setComments, setTotalPages);
      }, [page]);
      return (
        <Wrap>
          <h3 className="h3 with-icon">
            <Comment/>
            <span>รีวิว Gclub โดยผู้เล่น</span>
          </h3>
          {comments.length ? <div className='reviews-list'>
            {comments.map((item) => <div className="li" key={item.id + Math.random() * 100 + '-w-comment'}>
              <div className='name'>
                <UserIcon author={item.author_name} emoji={item.emoji}/>
                <div className='user-details'>
                  <span className='user-name'>{item.author_name}</span>
                  <div className="rating-date">
                    <span className='request-time'>{item.request_time}</span>
                    {item.rating &&
                    <RatingHolder>
                      <StarsFull n={item.rating} cssClass='rating rating-active'/>
                      <StarsEmpty n='5' cssClass='rating rating-passive'/>
                    </RatingHolder>
                    }
                  </div>
                </div>
              </div>
              <ReviewText dangerouslySetInnerHTML={{ __html: item.content }}/>
              </div>
            )}
            {((totalPages === false) || (totalPages > page)) && <div className="a-center">
              <button className="button transparent-button" onClick={() => setPage(page + 1)}>
                <span>ความคิดเห็นเพิ่มเติม</span>
              </button>
            </div>}
          </div> : <div>{totalPages * 1 === 0 ? 'ไม่มีความคิดเห็น. เป็นคนแรก' : 'กำลังโหลด ...'}</div>}
        </Wrap>
      );
    }
    }
  />
);
export default CommentsStaticLoadmore;
